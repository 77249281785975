/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

/* @import Google Fonts */
@font-face {
    font-family: 'Montserrat';
    src: url('~/src/assets/fonts/google-fonts/Montserrat.ttf');
}

@font-face {
    font-family: 'Orbitron';
    src: url('~/src/assets/fonts/google-fonts/Orbitron.ttf');
}

/* @import Block UI scss */
@import '@core/scss/angular/libs/blockui.component.scss';

/* @import Datatable scss */
@import '@core/scss/angular/libs/datatables.component.scss';

/* @import Apex scss */
@import '@core/scss/base/plugins/charts/chart-apex';

/* @import toastr scss */
@import '@core/scss/angular/libs/toastr.component.scss';

/* @import sweet alert scss */
@import '@core/scss/angular/libs/sweet-alerts.component.scss';

/* @import Flat Picker */
@import '@core/scss/angular/libs/flatpickr.component.scss';

/* @import Swiper scss */
@import '@core/scss/angular/libs/swiper.component.scss';

/* @import noui Slider scss */
@import '@core/scss/angular/libs/noui-slider.component.scss';

/* @import Select Component scss */
@import '@core/scss/angular/libs/select.component.scss';

/* @import File Uploader scss */
@import '@core/scss/angular/libs/file-uploader.component.scss';

/* @import Form Wizard scss */
@import '@core/scss/angular/libs/form-wizard.component.scss';

/* Scroll Bar  */
::-webkit-scrollbar {
    /* width */
    width: 5px;
    height: 5px;

    /* &:hover {
        width: 7px;
        height: 7px;
    } */
}

::-webkit-scrollbar-track {
    /* Track */
    background: #fff;
}

::-webkit-scrollbar-thumb {
    /* Handle */
    background: #b8b8b8;
    border-radius: 0.428rem;

    &:hover {
        background: #676767;
    }
}

/* Unable to Select */
.unable-to-select {
    user-select: none !important;
}

/* Required Star */
.required-star {
    &::after {
        content: " *";
        color: #b8494c;
    }
}

/* Sidbar fixed close button */
.fixed-close-button .close {
    background: transparent !important;
    box-shadow: none !important;
    position: unset !important;
    transform: none !important;
    transition: none !important;
}

/* Max Z Index */
.max-z-index {
    z-index: 99999999999 !important;
}

.custom-group {
    padding-top: calc(0.438rem + 1px);
    padding-bottom: calc(0.438rem + 1px);
}

/* Map popup css */
.leaflet-popup-content {
    margin: 0px 12px 10px 10px !important;

    .popup-window {
        margin: 12px 6px 6px 6px !important;

        .popup-plain-text {
            font-size: 12px;
            /* white-space: nowrap; */
            font-style: italic;
            font-weight: bold;
            padding: 6px;
        }

        .popup-title {
            font-size: 12px;
            white-space: nowrap;
            font-style: italic;
            font-weight: bold;
            color: #3276b1;
            padding: 12px;
            border-bottom: 2px solid;
            margin-bottom: 1rem;
        }

        .popup-content {
            line-height: 1.2rem;
            white-space: nowrap;
            overflow: auto;

            table.info-table {
                tr>td:last-child {
                    text-align: end;
                }
            }
        }
    }
}

.text-highlight {
    background: yellow;
}

.activation-link-container {
    text-align: left;
    display: flex;
    align-items: center;

    p {
        white-space: nowrap;
        overflow: auto;
    }
}

.grecaptcha-badge {
    visibility: hidden;
}

.nowrap-mixin {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.no-transition {
    transition: none !important;
}

/* Safari syntax */
:-webkit-full-screen {
    background-color: #fff;
}

/* IE11 */
:-ms-fullscreen {
    background-color: #fff;
}

/* Standard syntax */
:fullscreen {
    background-color: #fff;
}

/* Map Cluster Settings */
.marker-cluster {
    color: #fff;
    font-weight: bolder;
}

.cluster {
    color: #fff;
    font-weight: bolder;
    padding-top: 3px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat !important;
    background-attachment: fixed !important;
    background-position: center !important;

    &.cluster-m1 {
        background: url('~/src/assets/images/map/m1.png');
        background-size: 53px 52px;
    }

    &.cluster-m2 {
        background: url('~/src/assets/images/map/m2.png');
        background-size: 56px 55px;
    }

    &.cluster-m3 {
        background: url('~/src/assets/images/map/m3.png');
        background-size: 66px 65px;
    }

    &.cluster-m4 {
        background: url('~/src/assets/images/map/m4.png');
        background-size: 78px 77px;
    }

    &.cluster-m5 {
        background: url('~/src/assets/images/map/m5.png');
        background-size: 90px 89px;
    }
}

.modal-header.escheresque-banner {
    background-image: url('~/src/assets/images/banner/escheresque.png') !important;
}